<template>
  <error-message 
    v-if="errorLabel" 
    :textLabel="errorLabel" 
    icon="fa fa-warning"
  />
  <div 
    v-else 
    class="info-container"
  >
    <div 
      class="info" 
      :class="{'small-bottom-margin': !description}"
    >
      <div class="lhs">
        <div class="identity">
          <slot name="identity"></slot>
        </div>
        <div class="identity-panel">
          <div class="identity-details">
            <h1 class="name title">
              {{ name }}
            </h1>
            <div 
              class="description" 
              :style="description !== '' ? { 'padding-top': '5px' } : {}"
            >
              {{ description }}
            </div>
          </div>
          <div 
            v-if="mobile" 
            class="status-actions"
          >
            <base-badge 
              v-if="state" 
              class="status mobile" 
              :state="state.label" 
              :color="state.color"
            />
            <div 
              v-if="mobile" 
              class="rhs"
            >
              <slot name="rhs"></slot>
            </div>
          </div>
          <base-badge 
            v-if="state" 
            class="status not-mobile" 
            :state="state.label" 
            :color="state.color"
          />
        </div>
      </div>
      <div 
        v-if="!mobile" 
        class="rhs"
      >
        <slot name="rhs"></slot>
      </div>
    </div>
    <div class="line-separator"></div>
    <list-row 
      :noBorder="true" 
      class="summary-row"
    >
      <list-column 
        v-for="summary in summaryColumns"
        :key="summary.label" 
        class="summary-column" 
      >
        <div class="description">
          {{ $tn(summary.label) }}
        </div>
        <div class="summary-info">
          <base-icon 
            v-if="summary.icon" 
            :icon="summary.icon"
          />
          <base-image 
            v-if="summary.logo" 
            class="logo" 
            :src="summary.logo" 
            :altText="summary.value ? summary.value : ''"
          />
          <div 
            v-if="summary.state" 
            class="info-status"
          >
            <base-icon
              :title="$tn(summary.state.label)" 
              :icon="`${summary.state.icon} ${summary.state.color}`"
            />
            <div class="text-status">
              {{ $tn(summary.state.label) }}
            </div>
          </div>
          <div v-else-if="summary.value">
            <router-link 
              v-if="summary.to" 
              :to="summary.to"
            >
              {{ summary.value }}
            </router-link>
            <template v-else>
              {{ summary.value }}
            </template>
          </div>
          <div v-else-if="summary.basetag">
            <base-tag 
              colorClass="light_grey" 
              :tag="summary.basetag"
            />
          </div>
          <div v-else-if="summary.basebadge">
            <base-badge 
              :state="summary.basebadge" 
              :animate="summary.animated" 
              :color="summary.color"
              :interpolation="summary.interpolation"
            />
          </div>
          <div v-else>
            <slot 
              :name="summary.label" 
              :summary="summary"
            ></slot>
          </div>
        </div>
      </list-column>
    </list-row>
    <div class="line-separator"></div>
    <div class="details">
      <slot name="details"></slot>
    </div>
  </div>
</template>
<script>
import { isMobileMode } from '@/utils';

export default {
  name: 'BaseDetails',
  components: { },
  props: {
    name: {
      type: String,
      required: true,
    },
    summaryColumns: {
      type: Array,
      required: true,
    },
    state: {
      type: Object,
    },
    description: {
      type: String,
    },
    errorLabel: {
      type: String,
    },
  },
  computed: {
    mobile() {
      return isMobileMode();
    },
  },
};
</script>
<style scoped lang="scss">
@import '@/styles/mixins';
.logo {
  margin-right: 5px;
}
.info {
  margin: 0 0 30px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .identity {
    margin-right: 30px;
    @include phone {
      margin-right: 20px;
    }
  }

  .identity-panel {
    width: 100%;
    @include phone {
      text-align: left;
      align-items: flex-start;
      flex-wrap: wrap;
    }
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    .identity-details {
      @include phone {
        margin-top: 5px;
      }
      align-items: flex-start;
      margin-right: 10px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .description {
        margin: 0;
        @include phone {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }

  .name {
    font-size: 24px;
    margin-right: 30px;
    @include phone {
      font-size: 15px;
    }
  }

  @include phone {
    .status-actions {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }
    .status {
      margin-top: 5px;
    }
  }

  .lhs {
    width: 100%;
    display:flex;
    align-items: flex-end;
    @include phone {
      align-items: center;
    }
  }

  .rhs {
    display: flex;
    align-items: flex-end;
    @include phone {
      align-items: flex-start;
    }
  }
}

.small-bottom-margin {
  margin-bottom: 15px;
}

.summary-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  &.list-row {
    padding: 15px 0;
    &:hover {
      background-color: inherit;
    }
  }
  .summary-column {
    padding: 5px 0;
    text-align: left;
    em {
      margin-right: 6px;
    }
    .summary-info {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      width: 100%;
      font-size: 16px;
      // margin-bottom: 5px;
      @include phone {
        font-size: 14px;
      }
    }
    .description {
      margin-bottom: 5px;
    }
  }
}

.details {
  margin-top: 20px;
}

.info-status {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.logo {
  height: 22px;
  width: 22px;
}

</style>
