export const currencyMixin = {
  methods: {
    // removes all letters before currency symbol (ie. CA$100.00 -> $100.00)
    formatShortCurrency(amount, currentCurrency) {
      const fullCurrency = this.formatCurrency(amount, currentCurrency);
      const currencySymbol = fullCurrency.replace(/[-.,A-Z\d\s]/gi, '');
      const currencyWithOnlyDigits = (fullCurrency).replace(/[^0-9.,]/g, '');
      return fullCurrency.includes('-') // check if it's discount string
        ? '-'.concat(currencySymbol).concat(currencyWithOnlyDigits)
        : currencySymbol.concat(currencyWithOnlyDigits);
    },
    formatCurrency(amount, currentCurrency) {
      let tempAmount = amount;
      if (isNaN(Number(amount))) {
        tempAmount = 0;
      }
      if (!currentCurrency) {
        return `$${tempAmount.toFixed(2)}`;
      }
      return this.$n(Number(tempAmount), 'currency', { currency: currentCurrency });
    },
    formatShortLocaleCurrency(amount, currentCurrency, local, maxFractionDigits) {
      const fullCurrency = this.formatLocaleCurrency(amount, currentCurrency, local, maxFractionDigits);
      const currencySymbol = fullCurrency.replace(/[-.,A-Z\d\s]/gi, '');
      const currencyWithOnlyDigits = (fullCurrency).replace(/[^0-9.,]/g, '');
      return fullCurrency.includes('-') // check if it's discount string
        ? '-'.concat(currencySymbol).concat(currencyWithOnlyDigits)
        : currencySymbol.concat(currencyWithOnlyDigits);
    },
    formatLocaleCurrency(amount, currency, local, maxFractionDigits) {
      let tempAmount = amount;
      if (isNaN(Number(amount))) {
        tempAmount = 0;
      }
      if (typeof tempAmount === 'string' || tempAmount instanceof String) {
        tempAmount = Number(tempAmount);
      }
      if (!currency) {
        return `$${tempAmount.toFixed(2)}`;
      }
      const options = {
        style: 'currency',
        currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: maxFractionDigits
      };
      return Intl.NumberFormat(local, options)
        .format(tempAmount);
    },
    formatUsage(usage) {
      return Intl.NumberFormat(navigator.language, {
        maximumFractionDigits: 6,
      }).format(usage);
    },
    formatLocaleCurrencyWithoutAbbreviation(amount, currency, local, maxFractionDigits) {
      let tempAmount = amount;
      if (isNaN(Number(amount))) {
        tempAmount = 0;
      }
      if (!currency) {
        return `$${tempAmount.precision(maxFractionDigits)}`;
      }
  
      const options = {
        style: 'currency',
        currency,
        currencyDisplay: 'symbol',
      };
      if(maxFractionDigits) {
        options.maximumFractionDigits = maxFractionDigits;
      }else{
        options.minimumFractionDigits = 0;
      }
      return Intl.NumberFormat(local, options)
        .format(tempAmount);
    },
  },
};
